
import item5Detail from "@/components/OrderList/Item5Detail2.js";
import { Dialog } from 'vant';
import { miceService } from "@/service/miceService.js";
import { Indicator } from 'mint-ui';
import { SmartStorage } from "smart-core-util";
// import iJsBridge from "smart-javascript-bridge";
import { Toast } from 'mint-ui';
import getLocationPro from "@/utils/getLocation.js";
import iJsBridge from "./ijsBridge";
import photoLog from '@/components/PhotoLog/plugin.js'
export default {
  mixins: [item5Detail],
  methods: {
    async getLocationFailed(onlyGetLocation) {
      return new Promise(async(resolve, reject) => {
        if(!onlyGetLocation && !this.mealRules.electricFence){
          resolve(true)
          return
        }
        getLocationPro().then(async(option)=>{
          console.log('=-=-=option-=-=-',option);
          // 定位成功
          if(option && option.success){
            this.imygeoLocation = {
              nl: option.nl,
              el: option.el,
              ...option
            };
            if(onlyGetLocation){// 仅仅获取定位经纬度或者电子围栏关闭
              resolve(option)
            }else{
              await this.checkDistance()
              resolve(option)
            }
            // 定位失败
          }else if(option && !option.success){
            console.log('=-=-=option-=-=-',option);
            let positionClosed = false // 检查是否开启定位true: 关闭，false：开启
            for (const item of option.errorList) {
              positionClosed = (item.message.indexOf('denied') != -1 || 
                item.message.indexOf('not have sufficient geolocation permissions') != -1 || 
                item.message.indexOf('Not found GPS') != -1)
              this.imygeoLocation = {
                nl: '',
                el: '',
                message: item.message || '定位失败',
                success: option.success
              }
              if(positionClosed){
                break
              }
            }
            if(this.$route.name != 'orderSettle'){
              positionClosed = false
            }
            //白名单不强制卡控
            if(this.mealRules?.whiteList?.includes(SmartStorage.get("userId"))){
              positionClosed = false
            }
            console.log('=-=-=positionClosed-=-=-',positionClosed);
            if(this.mealRules.electricFence && positionClosed) {
              // electricFence:电子围栏开关
              let msg = "请检查移动设备定位是否开启且允许当前APP获取位置信息同时网络是否正常，如未开启或允许请设置后再次操作结账，点击“确定”后会回到用餐列表"
              if(this.event.tenant_code == "roche"){
                msg = '因支付系统升级，请在支付前打开并允许设备获取您的GPS定位，设置后再次操作结账\niPad设备，可在【设置 - 隐私与安全性 - 定位服务】中打开定位权限\n其他移动端设备，可在【设置 - 应用和服务】中打开定位权限'
              }
              Dialog.confirm({
                title: "提示",
                message: msg,
                confirmButtonText: "确定",
                showCancelButton: false,
                closeOnClickOverlay: false
              }).then(async action => {
                if (action == "confirm") {
                  this.$router.push({ path: "/orderList" });
                  location.reload()
                  resolve(false)
                }
              });
            }else{
              console.log('--====getLocationFailed定位失败====--');
              if(!onlyGetLocation){
                await this.checkDistance()
              }
              resolve(false)
            }
          }else{
            resolve(false)
          }
        })
      })
    },
    async checkDistance() {
      return new Promise(async (resolve, reject) => {
        //企业微信跳转到浏览器，获取需求详情
        if(SmartStorage.get("goAlipay") && SmartStorage.get("itemId") && !this.orderDetail){
          const params = {
              itemId: SmartStorage.get("itemId")
          };
          miceService.getMealItemDetail(params).then(res => {
              if (res && res.success) {
                this['ORDER_DETAIL'](
                  {
                    proposalId: SmartStorage.get("eventData").proposalId,
                    itsItemData: res.content
                  }
                );
              }
          });
        }
        let queryParams = {
          NodeCode: "SubmitGpsFail",
          ContextDataKeys: {
            ActionStart: "ActionStart",
            ItemId: this.orderDetail.itemId,
            EL: this.imygeoLocation.el,
            NL: this.imygeoLocation.nl,
            Distance: 0
          }
        }
        var distance
        if(this.imygeoLocation.success){
          //定位地点
          let m1 = new AMap.Marker({
            position: new AMap.LngLat(
              this.imygeoLocation.el,
              this.imygeoLocation.nl
            )
          });
          //餐厅地点
          let orderGPS;
          try {
            orderGPS = this.orderDetail.itsItemData.gps || JSON.parse(this.orderDetail.itsItemData.gps);
          } catch (error) {
            orderGPS = {
              el: 0,
              nl: 0
            }
          }
          let el = Number(orderGPS.el) || 0;
          let nl = Number(orderGPS.nl) || 0;
          let m2 = new AMap.Marker({
            position: new AMap.LngLat(el, nl)
          });
          var p1 = m1.getPosition();
          var p2 = m2.getPosition();
          distance = Math.round(p1.distance(p2));
          queryParams.ContextDataKeys.Distance = distance
        }else{
          queryParams.ContextDataKeys.positionClosed = this.imygeoLocation.message
        }
        console.log("distance===", distance);
        this.goPay = false;
        await miceService.locationFailed(queryParams)
        if(this.$route.name != 'orderSettle'){
          resolve(true)
          return
        }
        let msg = `当前支付地点可能不在${this.orderDetail.itsItemData.shopName}餐厅附近，如您已更换餐厅用餐，请点击“餐厅更换”，更换餐厅后重新支付。`
        let electricFenceF = false // 是否电子围栏弹框提示更换餐厅
        let showCancelButton = true // 是否显示打卡签到按钮
        if(this.event.tenant_code == "roche" && (Number(distance) > (this.mealRules.electricFenceDistance || 500) && this.mealRules.electricFence && this.orderDetail.sourceCode != "MeiTuan")){
          electricFenceF = true
          showCancelButton = false
        }
        if(this.event.tenant_code != "roche"){
          if((Number(distance) > (this.mealRules.electricFenceDistance || 500) && this.mealRules.electricFence && this.orderDetail.sourceCode != "MeiTuan") || (!this.imygeoLocation.success && this.mealRules.electricFence)) electricFenceF = true
          msg = `系统检测到您当前位置不在${this.orderDetail.itsItemData.shopName}餐厅范围内：1.请先检查平台餐厅与实际支付餐厅是否一致，若不一致，请点击【餐厅更换】，更换为实际用餐餐厅后再结账。2.若确认平台预订餐厅与实际用餐餐厅一致，且支付是在餐厅范围内，请点击【打卡签到】拍摄上传餐厅门头照片。`
        }
        if (electricFenceF) {
          Dialog.confirm({
            title: "提示",
            message: msg,
            confirmButtonText: "餐厅更换",
            cancelButtonText:'打卡签到',
            showCancelButton: showCancelButton,
            closeOnClickOverlay: true
          }).then(async action => {
            //更换餐厅
            if (action == "confirm") {
              this.changeShop(this.orderDetail);
            }
          }) .catch((action) => {
            if(action == 'cancel'){
              let photoLogPage =  new photoLog({});
              photoLogPage.render({
                pageData:{
                  orderDetail: this.orderDetail,
                  ...this.imygeoLocation
                },
                onConfirm: function(data) {
                  resolve(true)
                }.bind(this)
              })
            }
          });
        } else {
          resolve(true)
        }
      })
    },
  }
}